/* @import url('https://unpkg.com/purecss@2.0.6/build/pure-min.css'); */
body{
    text-align: center;
}
@media only screen and (min-width: 601px) {
    .imageLinks{
        width:60%;
        max-width:600px;
    }
}
@media only screen and (max-width: 600px) {
    .pure-table{
        width:100%;
    }
    .imageLinks{
        width:100%;
    }
}
.imageLinks img{
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}
.pure-table {
    /* position:absolute; */
    /* bottom:0; */
	border-collapse: collapse;
	border-spacing: 0;
	empty-cells: show;
	border: 1px solid #cbcbcb
}
.pure-table caption {
	color: #000;
	font: arial, sans-serif;
    font-weight: bold;
	padding: 1em 0;
	/* text-align: center */
}
.pure-table td,
.pure-table th {
	border-left: 1px solid #cbcbcb;
	border-width: 0 0 0 1px;
	font-size: inherit;
	margin: 0;
	overflow: visible;
	padding: .5em 1em
}
.pure-table thead {
	background-color: #e0e0e0;
	color: #000;
	text-align: left;
	vertical-align: bottom
}
.pure-table td {
	background-color: transparent
}
.pure-table-bordered td {
    border-bottom:1px solid #cbcbcb
}
.pure-table-bordered tbody>tr:last-child>td{
    border-bottom-width:0
}
